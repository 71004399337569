import React, { useState } from 'react';
import './App.css';

import { Editor } from '@tinymce/tinymce-react'
import { useForm, useField, splitFormProps } from 'react-form'

function SelectField(props) {
    const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);
  
    const {
        value = options[0],
        setValue
    } = useField(field, fieldOptions);
  
    const handleSelectChange = e => {
      setValue(e.target.value);
    };
  
    return (
        <>
            <select {...rest} value={value} onChange={handleSelectChange} className="form-control">
            {options.map(option => (
                <option key={option} value={option}>
                {option}
                </option>
            ))}
            </select>
            {value !== 'w pełni zgodna' ? 
            (<div className="">
                <div className="clearfix form-group-separator"></div>
                <label>
                    Treści niedostępne:
                </label>
                <Niezgodnosc />
            </div>)
            : null}
        </>
    );
}

function NazwaPlacowki() {
    const { getInputProps } = useField("nazwaplacowki");
    return <input className="form-control" {...getInputProps()} />
}

function Architektura() {
    const { value, setValue } = useField("architektura");
    // return <textarea className="form-control" {...getInputProps()} />
    return <Editor
    // initialValue={value}
    newValue={value}
    init={{
        language: 'pl',
        menubar: false,
        plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'code insertdatetime media table paste'
        ],
        toolbar:
            'code | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'
    }}
    onChange={e => {setValue(e.target.getContent())}}
    />
}

function Komunikacja() {
    const { value, setValue } = useField("komunikacja");
    // return <textarea className="form-control" {...getInputProps()} />
    return <Editor
    // initialValue={value}
    newValue={value}
    init={{
        language: 'pl',
        menubar: false,
        plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'code insertdatetime media table paste'
        ],
        toolbar:
            'code | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'
    }}
    onChange={e => {setValue(e.target.getContent())}}
    />
}

function Niezgodnosc() {
    const { value, setValue } = useField("niezgodnosc");
    // return <textarea className="form-control" {...getInputProps()} />
    return <Editor
    apiKey="5v0vkdcrcvfgrf6u96jj1up8uiv92rpmmi3448kxbloyfcqk"
    // initialValue={value}
    newValue={value}
    // value={value}
    init={{
        language: 'pl',
        menubar: false,
        plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'code insertdatetime media table paste'
        ],
        toolbar:
            'code | undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent'
    }}
    onChange={e => {setValue(e.target.getContent())}}
    />
}

function AdresStrony() {
    const { getInputProps } = useField("adresstrony");
    return <input type="url" className="form-control" {...getInputProps()} />
}

function DataPublikacji() {
    const { getInputProps } = useField("datapublikacji");
    return <input className="form-control" {...getInputProps()} type="date" />
}

function DataAktualizacji() {
    const { getInputProps } = useField("dataaktualizacji");
    return <input className="form-control" {...getInputProps()} type="date" />
}

function DataPrzegladu() {
    const { getInputProps } = useField("dataprzegladu");
    return <input className="form-control" {...getInputProps()} type="date" />
}

function DataSporzadzenia() {
    const { getInputProps } = useField("datasporzadzenia");
    return <input className="form-control" {...getInputProps()} type="date" />
}

function Email() {
    const { getInputProps } = useField("email");
    return <input type="email" className="form-control" {...getInputProps()} />
}

function Telefon() {
    const { getInputProps } = useField("telefon");
    return <input className="form-control" {...getInputProps()} />
}

function OsobaOdpowiedzialna() {
    const { getInputProps } = useField("osobaodpowiedzialna");
    return <input className="form-control" {...getInputProps()} />
}

function MyForm(props) {

    const {
        Form,
        meta: { isSubmitting, canSubmit }
    } = useForm({
        onSubmit: async (values, instance) => {
            if (!values.zgodnosc) {
                values.zgodnosc = "częściowo zgodna"
            }
            if (!values.datapublikacji) {
                values.datapublikacji = (new Date()).toISOString().split('T')[0]
            }
            if (!values.dataaktualizacji) {
                values.dataaktualizacji = (new Date()).toISOString().split('T')[0]
            }
            if (!values.dataprzegladu) {
                values.dataprzegladu = (new Date()).toISOString().split('T')[0]
            }
            if (!values.datasporzadzenia) {
                values.datasporzadzenia = (new Date()).toISOString().split('T')[0]
            }
            console.log(values)
            props.setValues(values)
        },
        // debugForm: true
    });

    return (
        <Form>
            
            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Nazwa placówki: 
                </label>
                <NazwaPlacowki />
            </div>

            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Adres strony internetowej (w formacie https://adresstrony.pl): 
                </label>
                <AdresStrony />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Imię i nazwisko osoby odpowiedzialnej za rozpatrywanie wniosków:
                </label>
                <OsobaOdpowiedzialna />
            </div>

            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Adres e-mail osoby odpowiedzialnej za rozpatrywanie wniosków:
                </label>
                <Email />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Nr telefonu osoby odpowiedzialnej za rozpatrywanie wniosków:
                </label>
                <Telefon />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Status pod względem zgodności strony z ustawą: 
                </label>
                <SelectField field="zgodnosc" options={["częściowo zgodna", "w pełni zgodna", "niezgodna"]}  />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                Dostępność architektoniczna: 
                </label>
                <Architektura />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                Dostępność komunikacyjno-informacyjna: 
                </label>
                <Komunikacja />
            </div>

            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Data publikacji strony internetowej: 
                </label>
                <DataPublikacji />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Data ostatniej istotnej aktualizacji: 
                </label>
                <DataAktualizacji />
            </div>

            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Data przeglądu deklaracji: 
                </label>
                <DataPrzegladu />
            </div>
            
            <div className="clearfix form-group-separator"></div>

            <div className="form-group form-pole-deklaracji col-sm-12 col-md-8">
                <label>
                    Oświadczenie sporządzono dnia: 
                </label>
                <DataSporzadzenia />
            </div>
            
            <div className="clearfix form-group-separator"></div>
    
            <div>
                <button type="submit" disabled={!canSubmit} className="btn btn-default">
                    Wygeneruj deklarację
                </button>
            </div>
        </Form>
    );
}


function Deklaracja(props) {
    const {nazwaplacowki, adresstrony, zgodnosc, niezgodnosc, datapublikacji, dataaktualizacji, dataprzegladu, datasporzadzenia, email, telefon, osobaodpowiedzialna, architektura, komunikacja} = props.formValues    

    let kod = `
        <section id="a11y-wstep">
            <p><span id="a11y-podmiot">${nazwaplacowki}</span> zobowiązuje się zapewnić dostępność swojej <span id="a11y-zakres">strony internetowej</span> zgodnie z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.</p>
            <p>Deklaracja dostępności dotyczy strony <a href="${adresstrony}" id="a11y-url">${adresstrony}</a>.</p>
        </section>

        <ul>
            <li>Data publikacji strony internetowej: <time id="a11y-data-publikacja" datetime="${datapublikacji}">${new Date(datapublikacji).toLocaleDateString('pl-PL', { day: 'numeric', month: 'long', year: 'numeric' })} r.</time></li>
            <li>Data ostatniej istotnej aktualizacji: <time id="a11y-data-aktualizacja" datetime="${dataaktualizacji}">${new Date(dataaktualizacji).toLocaleDateString('pl-PL', { day: 'numeric', month: 'long', year: 'numeric' })} r.</time></li>
        </ul>

        <h2>Stan dostępności cyfrowej</h2>
        `

    if (zgodnosc === 'w pełni zgodna') {
        kod += `<p id="a11y-status">Ta strona internetowa jest ${zgodnosc} z załącznikiem do ustawy o dostępności cyfrowej z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.</p>`
    } else {
        kod += `<p id="a11y-status">Ta strona internetowa jest ${zgodnosc} z załącznikiem do ustawy o dostępności cyfrowej z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej.</p>
        
        `
        kod += `<h2>Niedostępne treści</h2>

        ${niezgodnosc}

        `
    }

    kod += `
        <h2>Przygotowanie deklaracji dostępności</h2>
        <ul>
            <li>Data sporządzenia deklaracji: <time id="a11y-data-sporzadzenie" datetime="${datasporzadzenia}">${new Date(datasporzadzenia).toLocaleDateString('pl-PL', { day: 'numeric', month: 'long', year: 'numeric' })} r.</time></li>
            <li>Data ostatniego przeglądu deklaracji: <time id="a11y-data-przeglad" datetime="${dataprzegladu}">${new Date(dataprzegladu).toLocaleDateString('pl-PL', { day: 'numeric', month: 'long', year: 'numeric' })} r.</time></li>
        </ul>
        <p>Deklarację sporządziliśmy na podstawie samooceny w oparciu o <a href="https://www.gov.pl/attachment/1a3e2bb5-6d60-4897-ac2f-07a8e91e70ed">Listę kontrolną do badania dostępności cyfrowej strony internetowej v. 2.2 (docx, 0,12MB)</a>.</p>

        <h2>Informacje zwrotne i dane kontaktowe</h2>
        <p>Wszystkie problemy z dostępnością cyfrową tej strony internetowej możesz zgłosić do <span id="a11y-kontakt">${osobaodpowiedzialna}</span> — mejlowo <a id="a11y-email" href="mailto:${email}">${email}</a> lub telefonicznie <a href="tel:${telefon}" id="a11y-telefon">${telefon}</a>.</p>
        <p>Każdy ma prawo wystąpić z żądaniem zapewnienia dostępności cyfrowej tej strony internetowej lub jej elementów.</p>
        <p>Zgłaszając takie żądanie podaj:</p>
        <ul>
        <li>swoje imię i nazwisko,</li>
        <li>swoje dane kontaktowe (np. numer telefonu, e-mail),</li>
        <li>dokładny adres strony internetowej, na której jest niedostępny cyfrowo element lub treść,</li>
        <li>opis na czym polega problem i jaki sposób jego rozwiązania byłby dla Ciebie najwygodniejszy.</li>
        </ul>
        <p>Na Twoje zgłoszenie odpowiemy najszybciej jak to możliwe, nie później niż w ciągu 7 dni od jego otrzymania.</p>
        <p>Jeżeli ten termin będzie dla nas zbyt krótki poinformujemy Cię o tym. W tej informacji podamy nowy termin, do którego poprawimy zgłoszone przez Ciebie błędy lub przygotujemy informacje w alternatywny sposób. Ten nowy termin nie będzie dłuższy niż 2 miesiące.</p>
        <p>Jeżeli nie będziemy w stanie zapewnić dostępności cyfrowej strony internetowej lub treści, wskazanej w Twoim żądaniu, zaproponujemy Ci dostęp do nich w alternatywny sposób.</p>

        <section id="a11y-procedura">
            <h2>Obsługa wniosków i skarg związanych z dostępnością</h2>
            <p>Jeżeli w odpowiedzi na Twój wniosek o zapewnienie dostępności cyfrowej, odmówimy zapewnienia żądanej przez Ciebie dostępności cyfrowej, a Ty nie zgadzasz się z tą odmową, masz prawo złożyć skargę.</p>
            <p>Skargę masz prawo złożyć także, jeśli nie zgadzasz się na skorzystanie z alternatywnego sposobu dostępu, który zaproponowaliśmy Ci w odpowiedzi na Twój wniosek o zapewnienie dostępności cyfrowej.</p>
            <p><a href="https://www.gov.pl/web/gov/zloz-wniosek-o-zapewnienie-dostepnosci-cyfrowej-strony-internetowej-lub-aplikacji-mobilnej">Pomocne mogą być informacje, które można znaleźć na rządowym portalu gov.pl</a>.</p>
            <p>Możesz także poinformować o tej sytuacji <a href="https://bip.brpo.gov.pl/">Rzecznika Praw Obywatelskich</a> i poprosić o interwencję w Twojej sprawie.</p>
        </section>

        <h2>Pozostałe informacje</h2>

        <section id="a11y-architektura">
            <h3>Dostępność architektoniczna</h3>
            ${architektura}
        </section>

        <section id="a11y-komunikacja">
            <h3>Dostępność komunikacyjno-informacyjna</h3>
            ${komunikacja}
        </section>
    `
    return (
        <textarea className="form-control deklaracja" value={kod} />
    )
}


function App() {

    const [formValues, setFormValues] = useState({});
    // let formValues = null

    return (
        <div className="App">
            {/* <MyForm formValues={formValues} setFormValues={setFormValues} /> */}
            <MyForm setValues={(values) => { setFormValues(values)} } />
            {formValues.nazwaplacowki && formValues.adresstrony && formValues.zgodnosc && formValues.datapublikacji && formValues.dataaktualizacji && formValues.dataprzegladu && formValues.datasporzadzenia && formValues.email && formValues.telefon && formValues.osobaodpowiedzialna  ? <Deklaracja formValues={formValues} /> : '' }
        </div>
    );
}

export default App;
